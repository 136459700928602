import {
  Box,
  Typography,
  Grid,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  Table
} from '@mui/material'
import StatBox from './common/graph/StatBox'
import EmailIcon from '@mui/icons-material/Email'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import TrafficIcon from '@mui/icons-material/Traffic'
import SalesReportGraph from './common/graph/datagraph'
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react'
import { viewParticularTransactionProgress } from '../redux/transaction/transactionAction'
import { vendorDashboardProgress } from '../redux/auth/authAction'
import { getDecryptedData } from '../Styles/ComponentStyles/formStyles'


const VendorDashboard = () => {
    const [rows, setRows] = useState();
      const dispatch = useDispatch();

       useEffect(() => {
          const fetchData = async () => {
            await dispatch(vendorDashboardProgress());
          };
          fetchData();
        }, [dispatch]);
  const VendorTransactionData = useSelector(
    (state) => state.transaction?.particularViewTransaction?.data?.data
  );
  const vendorData = useSelector((state) => state.login?.vendorDashboard?.data?.data);
  const TotalTransaction=vendorData?.count?.TotalTransaction;
  const totaltransactionbyVendorCount=vendorData?.count?.totaltransactionbyVendorCount;


    useEffect(() => {
      ("useEffect");
     
      
      if (VendorTransactionData) {
      
        (preprocessData(VendorTransactionData));
  
        setRows(preprocessData(VendorTransactionData));
      } else {
        setRows([]);
      }
    }, []);

    const preprocessData = (data) => {
      if (!data) return [];
      if (!Array.isArray(data)) data = [data];
      return data.flatMap((item) => {
        const sale = item.Sale;
        if (!Array.isArray(sale)) {
          item.Sale = [sale];
        }
        return item.Sale.map((saleItem, index) => ({
          id: index + 1,
          TransactionId: item.transaction_serial_no || "",
          CustomerName: item.customer_name || "",
          TotalAmount: item.price || 0,
          AmountPaid: saleItem.price || 0,
          Status: saleItem.status || "Unpaid",
        }));
      });
    };
     useEffect(() => {
        const fetchData = async () => {
          const vendorId=getDecryptedData("vendorId")
          await dispatch(viewParticularTransactionProgress(vendorId));

        };
        fetchData();
      }, [dispatch]);

  return (
    <Box className='dashboard-container'>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Box className='stat-box'>
            <StatBox
              title={TotalTransaction}
              subtitle='Total Transaction'
              progress='0.75'
              increase={<span style={{ color: 'red' }}>↑</span>}
              icon={<EmailIcon sx={{ color: '#1976d2', fontSize: '26px' }} />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box className='stat-box'>
            <StatBox
              title={totaltransactionbyVendorCount}
              subtitle='Total Transaction by VendorCount'
              progress='0.50'
              increase={<span style={{ color: 'green' }}>↝</span>}
              icon={
                <PointOfSaleIcon sx={{ color: '#388e3c', fontSize: '26px' }} />
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={12} md={6}>
          <Paper className='graph-container'>
            <Typography
              variant='h6'
              className='graph-title'
              pt={2}
              pl={2}
              pb={2}
              fontWeight={'bold'}
            >
              Transaction Overview
            </Typography>
            <SalesReportGraph sx={{ overflow: 'hidden' }} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className='graph-container'>
            <Typography
              variant='h6'
              className='graph-title'
              pt={5}
              pl={2}
              pb={2}
              fontWeight={'bold'}
            >
              Recent Transactions
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead> 
                  <TableRow>
                    <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                      Transaction ID
                    </TableCell>
                    <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                      Name
                    </TableCell>
                    <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                      Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.slice(0, 6).map((row) => (
                    <TableRow
                    key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align='center' component='th' scope='row'>
                        {row.TransactionId}
                      </TableCell>
                      <TableCell align='center'>{row.CustomerName}</TableCell>
                      <TableCell align='center'>{row.AmountPaid}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid container spacing={2} pt={2}>
          <Grid item xs={12} md={12}>
            <Paper>
              <Typography
                variant='h6'
                className='graph-title'
                pt={5}
                pl={2}
                pb={2}
                fontWeight={'bold'}
              >
                Users
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                        Transaction ID
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                        Name
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                        Amount
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                        Payment Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   {rows && rows.slice(0, 6).map((transaction, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                    >
                      <TableCell component='th' scope='row' align='center'>
                        {transaction.TransactionId}
                      </TableCell>
                      <TableCell align='center'>{transaction.CustomerName}</TableCell>
                      <TableCell align='center'>
                        {transaction.TotalAmount}
                      </TableCell>
                      <TableCell align='center'>
                        {transaction.Status}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default VendorDashboard
