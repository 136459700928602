export const CUSTOMER_ADD_SUCCESS="CUSTOMER_ADD_SUCCESS";
export const CUSTOMER_ADD_FAILURE="CUSTOMER_ADD_FAILURE";
export const CUSTOMER_ADD_PROGRESS="CUSTOMER_ADD_PROGRESS";

export const CUSTOMER_UPDATE_SUCCESS="CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAILURE="CUSTOMER_UPDATE_FAILURE";
export const CUSTOMER_UPDATE_PROGRESS="CUSTOMER_UPDATE_PROGRESS";

export const CUSTOMER_VIEW_SUCCESS="CUSTOMER_VIEW_SUCCESS";
export const CUSTOMER_VIEW_FAILURE="CUSTOMER_VIEW_FAILURE";
export const CUSTOMER_VIEW_PROGRESS="CUSTOMER_VIEW_PROGRESS";

export const CUSTOMER_DELETE_SUCCESS="CUSTOMER_DELETE_SUCCESS";
export const CUSTOMER_DELETE_FAILURE="CUSTOMER_DELETE_FAILURE";
export const CUSTOMER_DELETE_PROGRESS="CUSTOMER_DELETE_PROGRESS";

export const CUSTOMER_PARTICULAR_VIEW_SUCCESS="CUSTOMER_PARTICULAR_VIEW_SUCCESS";
export const CUSTOMER_PARTICULAR_VIEW_FAILURE="CUSTOMER_PARTICULAR_VIEW_FAILURE";
export const CUSTOMER_PARTICULAR_VIEW_PROGRESS="CUSTOMER_PARTICULAR_VIEW_PROGRESS";
export const ADD_CUSTOMER_RESET_STATE="ADD_CUSTOMER_RESET_STATE";
export const UPDATE_CUSTOMER_RESET_STATE="UPDATE_CUSTOMER_RESET_STATE";