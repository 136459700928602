export const WALLET_ADD_SUCCESS="WALLET_ADD_SUCCESS";
export const WALLET_ADD_FAILURE="WALLET_ADD_FAILURE";
export const WALLET_ADD_PROGRESS="WALLET_ADD_PROGRESS";

export const WALLET_UPDATE_SUCCESS="WALLET_UPDATE_SUCCESS";
export const WALLET_UPDATE_FAILURE="WALLET_UPDATE_FAILURE";
export const WALLET_UPDATE_PROGRESS="WALLET_UPDATE_PROGRESS";

export const WALLET_DELETE_SUCCESS="WALLET_DELETE_SUCCESS";
export const WALLET_DELETE_FAILURE="WALLET_DELETE_FAILURE";
export const WALLET_DELETE_PROGRESS="WALLET_DELETE_PROGRESS";

export const WALLET_VIEW_SUCCESS="WALLET_VIEW_SUCCESS";
export const WALLET_VIEW_FAILURE="WALLET_VIEW_FAILURE";
export const WALLET_VIEW_PROGRESS="WALLET_VIEW_PROGRESS";

export const WALLET_PARTICULAR_VIEW_SUCCESS="WALLET_PARTICULAR_VIEW_SUCCESS";
export const WALLET_PARTICULAR_VIEW_FAILURE="WALLET_PARTICULAR_VIEW_FAILURE";
export const WALLET_PARTICULAR_VIEW_PROGRESS="WALLET_PARTICULAR_VIEW_PROGRESS";
export const WALLET_RESET_STATE="WALLET_RESET_STATE";