export const TRANSACTION_ADD_SUCCESS="TRANSACTION_ADD_SUCCESS";
export const TRANSACTION_ADD_FAILURE="TRANSACTION_ADD_FAILURE";
export const TRANSACTION_ADD_PROGRESS="TRANSACTION_ADD_PROGRESS";

export const TRANSACTION_UPDATE_SUCCESS="TRANSACTION_UPDATE_SUCCESS";
export const TRANSACTION_UPDATE_FAILURE="TRANSACTION_UPDATE_FAILURE";
export const TRANSACTION_UPDATE_PROGRESS="TRANSACTION_UPDATE_PROGRESS";

export const TRANSACTION_VIEW_SUCCESS="TRANSACTION_VIEW_SUCCESS";
export const TRANSACTION_VIEW_FAILURE="TRANSACTION_VIEW_FAILURE";
export const TRANSACTION_VIEW_PROGRESS="TRANSACTION_VIEW_PROGRESS";

export const TRANSACTION_DELETE_SUCCESS="TRANSACTION_DELETE_SUCCESS";
export const TRANSACTION_DELETE_FAILURE="TRANSACTION_DELETE_FAILURE";
export const TRANSACTION_DELETE_PROGRESS="TRANSACTION_DELETE_PROGRESS";

export const TRANSACTION_PARTICULAR_VIEW_SUCCESS="TRANSACTION_PARTICULAR_VIEW_SUCCESS";
export const TRANSACTION_PARTICULAR_VIEW_FAILURE="TRANSACTION_PARTICULAR_VIEW_FAILURE";
export const TRANSACTION_PARTICULAR_VIEW_PROGRESS="TRANSACTION_PARTICULAR_VIEW_PROGRESS";

export const TRANSACTION_CUSTOMER_PARTICULAR_VIEW_SUCCESS="TRANSACTION_CUSTOMER_PARTICULAR_VIEW_SUCCESS";
export const TRANSACTION_CUSTOMER_PARTICULAR_VIEW_FAILURE="TRANSACTION_CUSTOMER_PARTICULAR_VIEW_FAILURE";
export const TRANSACTION_CUSTOMER_PARTICULAR_VIEW_PROGRESS="TRANSACTION_CUSTOMER_PARTICULAR_VIEW_PROGRESS";
export const TRANSACTION_RESET_STATE="TRANSACTION_RESET_STATE";