import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography, Grid, Container,IconButton } from "@mui/material";
import {
  getDecryptedData,
  StyledButton,
  StyledTextField,
  styles,
} from "../../Styles/ComponentStyles/formStyles";
import CommonDialog from "../common/Dialogbox";
import "../../Styles/style.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetState,
  updateUserProgress,
  viewProfileInProgress,
} from "../../redux/auth/authAction";
import { APP_LINK } from "../common/sakthiMenu";

const UpdateAdmin = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  let { id } = useParams();
  if (!id || id === ":id") {
    id = getDecryptedData("userId");
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.login?.viewProfile.data.data);
  const UpdateUser = useSelector((state) => state.login?.updateUser.data);
  const userProfile = userData?.data || [];

  React.useEffect(() => {
    dispatch(viewProfileInProgress(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    if (userProfile) {
      setValue("first_name", userProfile.first_name);
      setValue("last_name", userProfile.last_name);
      setValue("address", userProfile.address);
      setValue("email", userProfile.email);
      setValue("contact", userProfile.contact);
    }
  }, [userProfile, setValue]);

  const onSubmit = (data) => {
    const formData = { id: id, data: data };
    dispatch(updateUserProgress(formData));
  };
  React.useEffect(() => {
    if (UpdateUser?.success) {
      setIsSuccess(true);
      setDialogOpen(true);
      const timer = setTimeout(() => {
        dispatch(ResetState());
        setDialogOpen(false);
        setIsSuccess(false);
        navigate(APP_LINK.DASHBOARD);
      }, 3000);
      return () => clearTimeout(timer);
    } else if (UpdateUser.error) {
      setIsSuccess(false);
      setDialogOpen(true);
    }
  }, [UpdateUser]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    navigate("/dashboard");
    dispatch(ResetState());
  };

  return (
    <Container>
      <Box sx={{ ...styles.title_box }}>
          <Typography variant="h5" gutterBottom sx={styles.title}>
          Update Admin
          </Typography>
          <IconButton>
            <ArrowBackIcon onClick={() => navigate(-1)} back />
          </IconButton>
        </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Box>
            <Typography
              variant="h6"
              fontSize={16}
              sx={styles.textFieldContainer}
            >
              First Name
            </Typography>
            <StyledTextField
              id="first_name"
              size="large"
              fullWidth
              multiline
              {...register("first_name", { required: "Name is required" })}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
            />
          </Box>
          <Box>
            <Typography
              variant="h6"
              fontSize={16}
              sx={styles.textFieldContainer}
            >
              Last Name
            </Typography>
            <StyledTextField
              id="last_name"
              size="large"
              fullWidth
              multiline
              {...register("last_name", { required: "LastName is required" })}
              error={!!errors.LastName}
              helperText={errors.LastName ? errors.name.message : ""}
            />
          </Box>
          <Box>
            <Typography
              variant="h6"
              fontSize={16}
              sx={styles.textFieldContainer}
            >
              Address
            </Typography>
            <StyledTextField
              id="address"
              rows={4}
              size="large"
              fullWidth
              multiline
              {...register("address", { required: "Address is required" })}
              error={!!errors.address}
              helperText={errors.address ? errors.address.message : ""}
            />
          </Box>
          <Box>
            <Typography
              variant="h6"
              fontSize={16}
              sx={styles.textFieldContainer}
            >
              Email Address
            </Typography>
            <StyledTextField
              id="email"
              size="large"
              fullWidth
              multiline
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
          </Box>
          <Box>
            <Typography
              variant="h6"
              fontSize={16}
              sx={styles.textFieldContainer}
            >
              Phone Number
            </Typography>
            <StyledTextField
              id="contact"
              type="tel"
              size="large"
              fullWidth
              multiline
              {...register("contact", {
                required: "Phone Number is required",
                pattern: {
                  value: /^[6789]\d{9}$/,
                  message:
                    "Invalid phone number, should start with 6, 7, 8 or 9 and be 10 digits long",
                },
                minLength: {
                  value: 10,
                  message: "Phone number should be 10 digits long",
                },
                maxLength: {
                  value: 10,
                  message: "Phone number should be 10 digits long",
                },
              })}
              error={!!errors.contact}
              helperText={errors.contact ? errors.contact.message : ""}
            />
          </Box>
          <Box sx={styles.submitGap}>
            <Grid item xs={3}>
              <StyledButton
                sx={styles.submitButtonContainer}
                //handleSubmit(onSubmit)
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </StyledButton>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* Dialog */}
      <CommonDialog
        open={dialogOpen}
        isSuccess={isSuccess}
        onClose={handleDialogClose}
        messageSuccess="User details Updated successfully!"
        messageError="Failed to update the User details."
      />
    </Container>
  );
};

export default UpdateAdmin;
