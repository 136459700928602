import {call, put, takeLatest} from 'redux-saga/effects';
import { setAuthHeader } from "../../Styles/ComponentStyles/formStyles"
import {
  ForgetPasswordFailure,
  ForgetPasswordSuccess,
  logInFailure,
  logInSuccess,
  RegisterOtpFailure,
  RegisterOtpSuccess,
  RequestOtpFailure,
  RequestOtpSuccess,
  ResendOtpFailure,
  ResendOtpSuccess,
  signUpInFailure,
  signUpInSuccess,
  ValidateOtpFailure,
  ValidateOtpSuccess,
  viewProfileInSuccess,
  viewProfileInFailure,
  updateUserSuccess,
  updateUserFailure,
  adminDashboardSuccess,
  adminDashboardFailure,
  vendorDashboardSuccess,
  vendorDashboardFailure,
} from './authAction';
import {
  AuthEffect,
  ForgetPasswordEffect,
  RegisterOtpEffect,
  RequestOtpEffect,
  ResendOtpEffect,
  SignUpEffect,
  ValidOtpEffect,
  adminDashboardEffect,
  userUpdateEffect,
  vendorDashboardEffect,
  viewUserProfileEffect,
} from './autheffect';
import {
  ADMIN_DASHBOARD_PROGRESS,
  FORGOT_PASSWORD_IN_PROGRESS,
  REGISTER_OTP_IN_PROGRESS,
  REQUEST_LOGIN_IN_PROGRESS,
  REQUEST_OTP_IN_PROGRESS,
  RESEND_OTP_IN_PROGRESS,
  SIGN_UP_IN_PROGRESS,
  USER_UPDATE_PROGRESS,
  VALIDATE_OTP_IN_PROGRESS,
  VENDOR_DASHBOARD_PROGRESS,
  VIEW_PROFILE_IN_PROGRESS,
} from './authTypes';

function* LoginWatcher({ payload }) {

  try {
    let {data} = yield call(AuthEffect, payload);
    setAuthHeader(data);
    yield put(
      logInSuccess({
        data: data,
        message: data['message'],
        authSuccess: true,
      }),
    );
  } catch (error) {

    yield put(
      logInFailure({
        authError: true,
        message: error.message || 'An error occurred during login.',
      }),
    );
  }
}

function* SignUpWatcher({payload}) {
  try {
    let {data} = yield call(SignUpEffect, payload);

    yield put(
      signUpInSuccess({
        data: data,
        message: data['message'],
        success: true,
      }),
    );
  } catch (err) {

    yield put(
      signUpInFailure(err),
    );
  }
}
function* RegisterOtpWatcher({payload}) {
  try {
    let {data} = yield call(RegisterOtpEffect, payload);
    yield put(
      RegisterOtpSuccess({
        data: data,
        message: data['message'],
        success: true,
      }),
    );
  } catch (err) {
    yield put(
      RegisterOtpFailure({
        error: true,
        message: err['message'],
      }),
    );
  }
}
function* ForgetPasswordWatcher({payload}) {

  try {
    let {data} = yield call(ForgetPasswordEffect, payload);

    yield put(
      ForgetPasswordSuccess({
        data: data,
        message: data['message'],
        success: true,
      }),
    );
  } catch (err) {
    yield put(
      ForgetPasswordFailure({
        error: true,
        message: err['message'],
      }),
    );
  }
}
function* RequestOtpWatcher({payload}) {
  try {
    let {data} = yield call(RequestOtpEffect, payload);
    yield put(
      RequestOtpSuccess({
        data: data,
        message: data['message'],
        success: true,
      }),
    );
  } catch (err) {
    yield put(
      RequestOtpFailure({
        error: true,
        message: err['message'],
      }),
    );
  }
}
function* ValidOtpWatcher({payload}) {
  try {
    let {data} = yield call(ValidOtpEffect, payload);

    yield put(
      ValidateOtpSuccess  ({
        data: data,
        message: data['message'],
        success: true,
      }),
    );
  } catch (err) {

    yield put(
      ValidateOtpFailure(err),
    );
  }
}
function* ResendOtpWatcher({payload}) {
  try {
    let {data} = yield call(ResendOtpEffect, payload);
    yield put(
      ResendOtpSuccess({
        data: data,
        message: data['message'],
        success: true,
      }),
    );
  } catch (err) {
    yield put(
      ResendOtpFailure({
        error: true,
        message: err['message'],
      }),
    );
  }
}
function* viewUserProfileWatcher({payload}) {
  try {
    let {data} = yield call(viewUserProfileEffect, payload);

    yield put(
      viewProfileInSuccess({
        data: data,
        message: data['message'],
        success: true,
      }),
    );
  } catch (err) {
    yield put(
      viewProfileInFailure({
        error: true,
        message: err['message'],
      }),
    );
  }
}
function* userUpdate({payload}){
  try{
      const {data} = yield call(userUpdateEffect,payload.id,payload.data);
      yield put(updateUserSuccess({
          data:data,
          message:data.message,
          success:true,
      }))
      }catch(error){
          yield put(updateUserFailure(error));
      }
}


function* adminDashboardsaga(){

  try{
      const {data} = yield call(adminDashboardEffect);

      yield put(adminDashboardSuccess({
          data:data,
          message:data,
          success:true,
      }))
      }catch(error){
          yield put(adminDashboardFailure(error));
      }
}
function* vendorDashboardsaga(){
  try{
      const {data} = yield call(vendorDashboardEffect);
      yield put(vendorDashboardSuccess({
          data:data,
          message:data.message,
          success:true,
      }))
      }catch(error){
          yield put(vendorDashboardFailure(error));
      }
}
export default function* AuthSaga() {
  yield takeLatest(REQUEST_LOGIN_IN_PROGRESS, LoginWatcher);
  yield takeLatest(SIGN_UP_IN_PROGRESS, SignUpWatcher);
  yield takeLatest(REGISTER_OTP_IN_PROGRESS, RegisterOtpWatcher);
  yield takeLatest(FORGOT_PASSWORD_IN_PROGRESS, ForgetPasswordWatcher);
  yield takeLatest(REQUEST_OTP_IN_PROGRESS, RequestOtpWatcher);
  yield takeLatest(VALIDATE_OTP_IN_PROGRESS, ValidOtpWatcher);
  yield takeLatest(RESEND_OTP_IN_PROGRESS, ResendOtpWatcher);
  yield takeLatest(VIEW_PROFILE_IN_PROGRESS, viewUserProfileWatcher);
  yield takeLatest(USER_UPDATE_PROGRESS, userUpdate);
  yield takeLatest(ADMIN_DASHBOARD_PROGRESS, adminDashboardsaga);
  yield takeLatest(VENDOR_DASHBOARD_PROGRESS, vendorDashboardsaga);
}
