import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  StyledContainer,
  paginationStyles,
} from "../../Styles/ComponentStyles/style";
import {
  StyledButton,
  StyledTextField,
} from "../../Styles/ComponentStyles/formStyles";

import { APP_LINK } from "../../screen/common/sakthiMenu";
import {
  viewAllCustomerProgress,
  deleteCustomerProgress,
} from "../../redux/Customer/customerAction";
import { CircleLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import {
  addWalletProgress,
  updateWalletProgress,
} from "../../redux/wallet/walletAction";

const ActionMenu = ({ user, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    onDelete(user.customerId);
    handleClose();
  };
  const handleEdit = () => {
    navigate(`/customer/edit/${user.customerId}`);
    handleClose();
  };
  const handleView = () => {
    navigate(`/customer/view/${user.customerId}`, {});
    handleClose();
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>View</MenuItem>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

const ViewVendor = () => {
  const [selection, setSelection] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [openAddWalletDialog, setOpenAddWalletDialog] = useState(false);
  const [openEditWalletDialog, setOpenEditWalletDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [walletData, setWalletData] = useState({});
  const customerData = useSelector(
    (state) => state.customer?.viewAllCustomer?.data
  );
  const customerList = customerData?.data || [];
  const totalRecords = customerData?.totalRecords || 0;
  const vendorPageSize = customerData?.pagesize || 10;

  const preprocessData = (data) =>
    data.map((item) => ({
      id: item.customer_serial_no,
      customerId: item.id,
      first_name: item.first_name || "",
      last_name: item.last_name || "",
      email: item.email || "",
      phone: item.phone || "",
      address: item.address || "",
      totalAmount: item.Wallet?.total_amount || 0,
      availableAmount: item.Wallet?.available_amount || 0,
      usedAmount: item.Wallet?.used_amount || 0,
    }));

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(viewAllCustomerProgress(currentPage));
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    fetchData();
  }, [dispatch, currentPage]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    if (customerData?.success) {
      setRows(preprocessData(customerList));
    } else {
      setRows([]);
    }
  }, [customerData]);

  const handleDeleteRow = (id) => {
    setVendorToDelete(id);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    if (vendorToDelete) {
      dispatch(deleteCustomerProgress(vendorToDelete));
      setRows((prevRows) =>
        prevRows.filter((row) => row.id !== vendorToDelete)
      );
      setVendorToDelete(null);
      setOpenDialog(false);
      dispatch(viewAllCustomerProgress(currentPage));
    }
  };

 
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleFilterCondition = (condition) => {
    switch (condition) {
      case "Condition1":
        setRows((prevRows) =>
          [...prevRows].sort((a, b) => a.first_name.localeCompare(b.first_name))
        );
        break;
      case "Condition2":
        setRows((prevRows) =>
          [...prevRows].sort((a, b) => b.created_at.localeCompare(a.created_at))
        );
        break;
      default:
        console.log(`Unknown filter condition: ${condition}`);
    }
    handleFilterClose();
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    try {
      const { customer_id, wallet_amount } = data;
      const formData = { customer_id, wallet_amount };


      if (isEditing) {
        setLoading(true);
        await dispatch(updateWalletProgress(formData));
        setTimeout(() => {
          setLoading(false);
          setOpenEditWalletDialog(false);
        }, 2000);
      } else if (!isEditing) {

        setLoading(true);
        await dispatch(addWalletProgress(formData));
        setTimeout(() => {
          setLoading(false);
          setOpenAddWalletDialog(false);
        }, 2000);
      }
      setOpenAddWalletDialog(false);
      dispatch(viewAllCustomerProgress(currentPage));
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  const filteredRows = preprocessData(customerList).filter((row) => {
    const fullName = `${row.first_name} ${row.last_name}`.toLowerCase();
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.phone.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleAddWallet = (row) => {

    setWalletData(row);
    setIsEditing(false); 
    setOpenAddWalletDialog(true);
    setOpenEditWalletDialog(false); 
    setValue("customerId", row.id);
    setValue("customer_id", row.customerId);
    setValue("wallet_amount", 0);
  };
  
  const handleEditWallet = (row) => {

    setWalletData(row);
    setIsEditing(true); 
    setOpenAddWalletDialog(false); 
    setOpenEditWalletDialog(true);
    setValue("customerId", row.id);
    setValue("customer_id", row.customerId);
    setValue("wallet_amount", 0);
    setValue("available_amount", row.availableAmount);
  };
  


  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
          <Box>
            <h1>Customer</h1>
            <p>Here is your customer list data</p>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <StyledTextField
            label="Search"
              variant="outlined"
              placeholder="Search..."
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Button
              variant="contained"
              onClick={() => navigate(APP_LINK.ADDCUSTOMER)}
              sx={{ backgroundColor: "#1d7f41", color: "#fff" }}
            >
              Add New
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box padding={3}>
        <StyledContainer>
          <Menu
            anchorEl={filterAnchorEl}
            open={Boolean(filterAnchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem onClick={() => handleFilterCondition("Condition1")}>
              First Name A-Z
            </MenuItem>
            <MenuItem onClick={() => handleFilterCondition("Condition2")}>
              Created At Newest to Oldest
            </MenuItem>
          </Menu>
          {loading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bgcolor="rgba(255, 255, 255, 0.5)"
              zIndex={1000}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <CircleLoader loading={loading} size={50} color="#1d7f41" />
              </Box>
            </Box>
          )}
          {!loading && (
            <>
              <Box>
                <Box
                  sx={{
                    boxShadow: 3,
                    width: "100%",
                    maxHeight: "100%",
                    overflowX: "auto",
                    overflowY: "hidden",
                    padding: "20px",
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="simple table"
                    sx={{ tableLayout: "fixed" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "120px",
                          }}
                        >
                          Customer ID
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "150px",
                          }}
                          align="center"
                        >
                          Name
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width:'150px',
                          }}
                          align="center"
                        >
                          Last Name
                        </TableCell> */}
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "250px",
                          }}
                          align="center"
                        >
                          Email
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "150px",
                          }}
                          align="center"
                        >
                          Phone Number
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "200px",
                          }}
                          align="center"
                        >
                          Address
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "150px",
                          }}
                          align="center"
                        >
                          Total Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "180px",
                          }}
                          align="center"
                        >
                          Available Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "150px",
                          }}
                          align="center"
                        >
                          Used Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "180px",
                          }}
                          align="center"
                        >
                          Wallets
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            width: "150px",
                          }}
                          align="center"
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows?.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {row.id}
                          </TableCell>
                          <TableCell align="center">
                            {row.first_name} {row.last_name}
                          </TableCell>
                          {/* <TableCell align="center" >{row.last_name}</TableCell> */}
                          <TableCell align="center">{row.email}</TableCell>
                          <TableCell align="center">{row.phone}</TableCell>
                          <TableCell align="center">{row.address}</TableCell>
                          <TableCell align="center">
                            {row.totalAmount}
                          </TableCell>
                          <TableCell align="center">
                            {row.availableAmount}
                          </TableCell>
                          <TableCell align="center">{row.usedAmount}</TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                gap: 2,
                              }}
                            >
                              <StyledButton
                                onClick={() => handleAddWallet(row)}
                              >
                                Add
                              </StyledButton>
                              <StyledButton
                                onClick={() => handleEditWallet(row)}
                                sx={{
                                  backgroundColor: "#eb6c47",
                                  color: "#fff",
                                }}
                              >
                                Edit
                              </StyledButton>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <ActionMenu user={row} onDelete={handleDeleteRow} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Box>

              {selection.length > 0 && (
                <div>
                  <h2>Selected Rows:</h2>
                  {selection.map((id) => (
                    <div key={id}>
                      {rows.find((row) => row.id === id)?.first_name}
                    </div>
                  ))}
                </div>
              )}
              <Box
                className="button-box"
                padding={{ xs: 1, sm: 3 }}
                display="flex"
                justifyContent="end"
              >
                <Button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  sx={paginationStyles.PreviousButton}
                >
                  Previous
                </Button>
                <Box sx={{ backgroundColor: "#e3e4eb" }}>
                  {Array.from(
                    { length: Math.ceil(totalRecords / vendorPageSize) },
                    (_, i) => i + 1
                  ).map((pageNumber) => (
                    <Button
                      key={pageNumber}
                      onClick={() => setCurrentPage(pageNumber)}
                      sx={{
                        backgroundColor:
                          currentPage === pageNumber ? "#fff" : "#e3e4eb",
                        color: currentPage === pageNumber ? "#000" : "#b6bee8",
                        ...paginationStyles.arrayButtons,
                      }}
                    >
                      {pageNumber}
                    </Button>
                  ))}
                </Box>
                <Button
                  onClick={() =>
                    setCurrentPage((prev) =>
                      Math.min(
                        prev + 1,
                        Math.ceil(totalRecords / vendorPageSize)
                      )
                    )
                  }
                  sx={paginationStyles.nextButton}
                >
                  Next
                </Button>
              </Box>
              <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                  Are you sure you want to delete this row?
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenDialog(false)} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={confirmDelete} color="primary">
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              {/* <Dialog
                open={openAddWalletDialog}
                onClose={() => setOpenAddWalletDialog(false)}
              >
                <DialogTitle>Add Wallet</DialogTitle>
                <DialogContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    
                    <StyledTextField
                      label="Customer Id"
                      sx={{ width: "100%", marginBottom: 2 }}
                      {...register("customerId", {
                        required: "Customer Id is required",
                        maxLength: {
                          value: 50,
                          message: "Customer Id cannot exceed 50 characters",
                        },
                      })}
                      error={!!errors.customerId}
                      helperText={
                        errors.customerId ? errors.customerId.message : ""
                      }
                      disabled
                    />
                 <StyledTextField
                    label="Customer ID"
                    {...register("customer_id", { required: "Customer ID is required" })}
                    error={!!errors.customer_id}
                    helperText={errors.customer_id?.message}
                  />


                    <StyledTextField
                      label="Wallet Amount"
                      sx={{ width: "100%" }}
                      {...register("wallet_amount", {
                        required: "Wallet Amount is required",
                        valueAsNumber: true,
                        min: {
                          value: 0.01,
                          message: "Wallet Amount must be greater than 0",
                        },
                      })}
                      error={!!errors.wallet_amount}
                      helperText={errors.wallet_amount?.message}
                    />
                  </Box>
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => setOpenAddWalletDialog(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit(onSubmit)} color="primary" >
                    Submit
                  </Button>

                </DialogActions>
              </Dialog>
              <Dialog
                open={openEditWalletDialog}
                onClose={() => setOpenEditWalletDialog(false)}
              >
                <DialogTitle>Add Wallet</DialogTitle>
                <DialogContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <StyledTextField
                      label="Customer Id"
                      sx={{ width: "100%", marginBottom: 2 }}
                      {...register("customerId", {
                        required: "Customer Id is required",
                        maxLength: {
                          value: 50,
                          message: "Customer Id cannot exceed 50 characters",
                        },
                      })}
                      error={!!errors.customerId}
                      helperText={
                        errors.customerId ? errors.customerId.message : ""
                      }
                      disabled
                    />
                    <StyledTextField
                      label="Customer ID"
                      sx={{ width: "100%", marginBottom: 2, display: "none" }}
                      {...register("customer_id", {
                        required: "Customer ID is required",
                        maxLength: {
                          value: 50,
                          message: "Customer ID cannot exceed 50 characters",
                        },
                      })}
                      error={!!errors.customer_id}
                      helperText={errors.customer_id?.message}
                      disabled
                    />{" "}
                    <StyledTextField
                      label="Available Amount"
                      sx={{ width: "100%", marginBottom: 2 }}
                      {...register("available_amount", {
                        required: "Available Amount is required",
                        valueAsNumber: true,
                        min: {
                          value: 0.01,
                          message: "Available Amount must be greater than 0",
                        },
                      })}
                      error={!!errors.wallet_amount}
                      helperText={errors.wallet_amount?.message}
                      disabled
                    />
                    <StyledTextField
                      label="Wallet Amount"
                      sx={{ width: "100%", marginBottom: 2 }}
                      {...register("wallet_amount", {
                        required: "Wallet Amount is required",
                        valueAsNumber: true,
                        min: {
                          value: 0.01,
                          message: "Wallet Amount must be greater than 0",
                        },
                      })}
                      error={!!errors.wallet_amount}
                      helperText={errors.wallet_amount?.message}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpenEditWalletDialog(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    color="primary"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog> */}
              <Dialog
                open={openAddWalletDialog || openEditWalletDialog}
                onClose={() => {
                  setOpenAddWalletDialog(false);
                  setOpenEditWalletDialog(false);
                }}
              >
                <DialogTitle>{isEditing ? "Edit Wallet" : "Add Wallet"}</DialogTitle>
                <DialogContent>
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <StyledTextField
                      label="Customer Id"
                      sx={{ width: "100%", marginBottom: 2 }}
                      {...register("customerId", {
                        required: "Customer Id is required",
                        maxLength: { value: 50, message: "Customer Id cannot exceed 50 characters" },
                      })}
                      error={!!errors.customerId}
                      helperText={errors.customerId?.message}
                      disabled
                    />
                    <StyledTextField
                      label="Wallet Amount"
                      sx={{ width: "100%", marginBottom: 2 }}
                      {...register("wallet_amount", {
                        required: "Wallet Amount is required",
                        valueAsNumber: true,
                        min: { value: 0.01, message: "Wallet Amount must be greater than 0" },
                      })}
                      error={!!errors.wallet_amount}
                      helperText={errors.wallet_amount?.message}
                    />
                    {isEditing && (
                      <StyledTextField
                        label="Available Amount"
                        sx={{ width: "100%", marginBottom: 2 }}
                        value={walletData.availableAmount}
                        disabled
                      />
                    )}
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenAddWalletDialog(false);
                      setOpenEditWalletDialog(false);
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit(onSubmit)} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>

            </>
          )}
        </StyledContainer>
      </Box>
    </>
  );
};

export default ViewVendor;
