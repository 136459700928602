import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CircleLoader } from "react-spinners";
import { viewParticularTransactionProgress } from "../../redux/transaction/transactionAction";
import { APP_LINK } from "../../screen/common/sakthiMenu";
import "../../Styles/style.css";
import { paginationStyles } from "../../Styles/ComponentStyles/style";
import { getDecryptedData,StyledTextField } from "../../Styles/ComponentStyles/formStyles";

const VendorPaymentList = () => {
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const VendorTransactionData = useSelector(
    (state) => state.transaction?.particularViewTransaction?.data?.data
  );

  const preprocessData = (data) => {
    if (!data) return [];
    if (!Array.isArray(data)) data = [data];
    return data.flatMap((item) => {
      const sale = item.Sale;
      if (!Array.isArray(sale)) {
        item.Sale = [sale];
      }
      return item.Sale.map((saleItem, index) => ({
        id: index + 1,
        TransactionId: item.transaction_serial_no || "",
        CustomerName: item.customer_name || "",
        TotalAmount: item.price || 0,
        CreatedDate: formatDateTime(item.created_at) || "",
        AmountPaid: saleItem.price || 0,
        PaidDate: formatDateTime(saleItem.date) || "",
        Status: saleItem.status || "Unpaid",
      }));
    });
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const vendorId=getDecryptedData("vendorId")
      setLoading(true);
      await dispatch(viewParticularTransactionProgress(vendorId));
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (VendorTransactionData) {
      setRows(preprocessData(VendorTransactionData));
    }
  }, [VendorTransactionData]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredRows = rows.filter((row) => {
    const fullName = `${row.CustomerName}`.toLowerCase();
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      row.CreatedDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.PaidDate.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <Box padding={3}>
      <Grid container spacing={2} padding={5}>
        <Grid item xs={12} sm={12} md={4} lg={ 4} xl={4}>
          <Box>
            <h2>TRANSACTIONS</h2>
            <p>Here is your Transactions list data</p>
          </Box>
        </Grid>
        <Grid item xs={0} sm={0} md={4} lg={4} xl={4}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate(APP_LINK.PAYPRIZE)}
              sx={{ backgroundColor: "#1d7f41", color: "#fff" }}
            >
              New Payment
            </Button>

          <StyledTextField
                      label="Search"
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
          </Box>
        </Grid>
      </Grid>
      <TableContainer>
        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor="rgba(255, 255, 255, 0.5)"
            zIndex={1000}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircleLoader loading={loading} size={50} color="#1d7f41" />
            </Box>
          </Box>
        )}
        {!loading && (
          <>
            <Box padding={3}>
              <Box
                sx={{
                  boxShadow: 3,
                  width: "100%",
                  maxHeight: "100%",
                  overflowX: "auto",
                  overflowY: "hidden",
                  padding: "30px",
                }}
              >
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: 'center'
                          }}>Transaction Id</TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: 'center'
                          }}>Customer Name</TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: 'center'
                          }}>Total Amount</TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: 'center'
                          }}>Created Date</TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: 'center'
                          }}>Amount Paid</TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: 'center'
                          }}>Paid Date</TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#1D7F41",
                            color: "#fff",
                            fontWeight: "bold",
                            textAlign: 'center'
                          }}>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.TransactionId}</TableCell>
                          <TableCell>{row.CustomerName}</TableCell>
                          <TableCell>{row.TotalAmount}</TableCell>
                          <TableCell>{row.CreatedDate}</TableCell>
                          <TableCell>{row.AmountPaid}</TableCell>
                          <TableCell>{row.PaidDate}</TableCell>
                          <TableCell>{row.Status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            </Box>
            <Box className="button-box" padding={{ xs: 1, sm: 3 }} display="flex" justifyContent="end">
              <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))} sx={paginationStyles.PreviousButton}>
                 Previous
              </Button>
              <Box sx={{ backgroundColor: "#e3e4eb" }}>
                {Array.from({ length: Math.ceil(rows.length / rowsPerPage) }, (_, i) => i).map((pageNumber) => (
                  <Button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    sx={{
                      backgroundColor: currentPage === pageNumber ? "#fff" : "#e3e4eb",
                      color: currentPage === pageNumber ? "#000" : "#b6bee8",
                      ...paginationStyles.arrayButtons,
                    }}
                  >
                    {pageNumber + 1}
                  </Button>
                ))}
              </Box>
              <Button
                onClick={() =>
                  setCurrentPage((prev) =>
                    Math.min(prev + 1, Math.ceil(rows.length / rowsPerPage) - 1)
                  )
                }
                sx={{ ...paginationStyles.nextButton }}
              >
                Next
              </Button>
            </Box>
          </>
        )}
      </TableContainer>
    </Box>
  );
};

export default VendorPaymentList;