import {
    Box,
    Typography,
    Grid,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    Table
  } from '@mui/material'
  import StatBox from './common/graph/StatBox'
  import EmailIcon from '@mui/icons-material/Email'
  import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
  import PersonAddIcon from '@mui/icons-material/PersonAdd'
  import TrafficIcon from '@mui/icons-material/Traffic'
  import SalesReportGraph from './common/graph/datagraph'
  import { useSelector, useDispatch } from "react-redux";
  import { viewParticularCustomerTransactionProgress} from "../redux/transaction/transactionAction";
  import { useEffect, useState } from 'react'
import { viewCustomerProgress } from '../redux/Customer/customerAction'
import { getDecryptedData } from '../Styles/ComponentStyles/formStyles'


  const CustomerDashboard = () => {
    const customerId = getDecryptedData("customerId");
      const [CustomerList, setCustomerList] = useState([]);
      const dispatch = useDispatch();
    const customerTransactionData = useSelector(
      (state) => state.transaction?.particularCustomerViewTransaction?.data
    );
     useEffect(() => {
        dispatch(viewParticularCustomerTransactionProgress(customerId));
      }, [dispatch]);
        useEffect(() => {
          const updatedVendorList = preprocessData(customerTransactionData.data);
          setCustomerList(updatedVendorList);
        }, [customerTransactionData]);
            const getCustomerData = useSelector((state) => state.customer?.ViewCustomer.data);
   
          const customerList = getCustomerData?.data || [];
          const wallet=customerList?.
          Wallet ||[];
   
       useEffect(() => {
            dispatch(viewCustomerProgress(customerId));
          }, [dispatch, customerId]);

      const preprocessData = (data) => {
        if (!data) {
          return [];
        }
        if (!Array.isArray(data)) {
          data = [data];
        }
        const updateData = data.map((item) => {
          const sale = item.Sale;
          if (!Array.isArray(sale)) {
            item.Sale = [sale];
          }
          return item.Sale.map((saleItem, index) => {
            return {
              id: index + 1,
              TransactionId: item.transaction_serial_no || "",
              CustomerName: item.customer_name || "",
              VendorName: item.vendor_name || "",
              TotalAmount: item.price || 0,
              AmountPaid: saleItem.price || 0,
              Status: saleItem.status || "Unpaid",
            };
          });
        }).flat();
        return updateData;
      };

    return (
      <Box className='dashboard-container'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box className='stat-box'>
              <StatBox
                title={wallet?.available_amount}
                subtitle='Total Amount'
                progress='0.75'
                increase={<span style={{ color: 'red',fontSize:'39px'  }}>↑</span>}
                icon={<EmailIcon sx={{ color: '#1976d2', fontSize: '26px' }} />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box className='stat-box'>
              <StatBox
                title='200'
                subtitle='Bonus Amount'
                progress='0.50'
                increase={<span style={{ color: 'green',fontSize:'39px' }}>↷</span>}
                icon={
                  <PointOfSaleIcon sx={{ color: '#388e3c', fontSize: '26px' }} />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box className='stat-box'>
              <StatBox
                title={wallet?.total_amount}
                subtitle='Available Amount'
                progress='0.30'
                increase={<span style={{ color: 'red',fontSize:'39px'  }}>↝</span>}
                icon={
                  <PersonAddIcon sx={{ color: '#f57c00', fontSize: '26px' }} />
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box className='stat-box'>
              <StatBox
                title={wallet?.used_amount}
                subtitle='Used Amount'
                progress='0.80'
                increase={<span style={{ color: 'green',fontSize:'39px'  }}>⤹</span>}
                icon={<TrafficIcon sx={{ color: '#d32f2f', fontSize: '26px' }} />}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} pt={2}>
          <Grid item xs={12} md={6}>
            <Paper className='graph-container'>
              <Typography
                variant='h6'
                className='graph-title'
                pt={2}
                pl={2}
                pb={2}
                fontWeight={'bold'}
              >
                Transaction Overview
              </Typography>
              <SalesReportGraph sx={{ overflow: 'hidden' }} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className='graph-container'>
              <Typography
                variant='h6'
                className='graph-title'
                pt={5}
                pl={2}
                pb={2}
                fontWeight={'bold'}
              >
                Recent Transactions
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                        Transaction ID
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                        Name
                      </TableCell>
                      <TableCell align='center' sx={{ fontWeight: 'bold' }}>
                        Amount
                      </TableCell>
  
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {CustomerList?.slice(0, 6).map((row) =>(      
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{row.TransactionId}</TableCell>
                        <TableCell align="center">{row.VendorName}</TableCell>
                        <TableCell align="center">{row.AmountPaid}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
      
        </Grid>
      </Box>
    )
  }
  
  export default CustomerDashboard
  