import axios from 'axios';
import { handleApiError } from "../Vendor/vendoreffect";
import { getDecryptedData } from '../../Styles/ComponentStyles/formStyles';
export const authInstance = axios.create({
  baseURL: 'http://154.61.173.102:3006',
  headers: {'content-type': 'application/x-www-form-urlencoded'},
});

const AuthEffect = formData => {
  return new Promise((resolve, reject) => {
    authInstance.request({
      url: '/auth/login',
      method: 'POST',
      data: formData,
    })
   .then(response => {
  resolve(response);
})
.catch(error => reject(error));
  });
};
const SignUpEffect = data => {
  return new Promise((resolve, reject) => {
    authInstance.request({
      url: '/auth/register',
      method: 'POST',
      data: data,
    })
    .then(response =>{
      resolve(response);
    }) 
    .catch(error => reject(handleApiError(error)));
  });
};

const RegisterOtpEffect = data => {
  return new Promise((resolve, reject) => {
    authInstance.request({
      url: '/auth/otpVerification',
      method: 'POST',
      data: data,
    })
    .then(response => resolve(response))
    .catch(error => reject(handleApiError(error)));
  });
};
const ValidOtpEffect = data => {
  return new Promise((resolve, reject) => {
    authInstance.request({
      url: '/auth/otpVerification',
      method: 'POST',
      data: data,
    })
    .then(response =>{

      resolve(response);
    })
    .catch(error => {

      reject(handleApiError(error));
    });
  });
};
const ForgetPasswordEffect = data => {
  return new Promise((resolve, reject) => {
    authInstance.request({
      url: '/auth/forgotpassword',
      method: 'POST',
      data: data,
    })
    .then(response =>{

      resolve(response);
    })
    .catch(error => reject(error));
  });
};

const RequestOtpEffect = data => {
  return new Promise((resolve, reject) => {
    authInstance.request({
      url: '/auth/otpMail',
      method: 'POST',
      data: data,
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
};

const ResendOtpEffect = data => {
  return new Promise((resolve, reject) => {
    authInstance.request({
      url: '/auth/otpVerification',
      method: 'POST',
      data: data,
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
};

const viewUserProfileEffect = id => {
  const token = getDecryptedData("accessToken");
  return new Promise((resolve, reject) => {
    authInstance.request({
      url: `user/getuser/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      resolve(response);
    })
    .catch(error => {
      reject(error);
    });
  });
};
export const userUpdateEffect = (id,formData) => {
  const accessToken = getDecryptedData("accessToken");
  return new Promise((resolve, reject) => {
    authInstance
      .request({
        url: `/user/updateuser/${id}`,
        method: "PUT",
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
       
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(
          handleApiError(error)
        );
      });
  });
};

export const adminDashboardEffect = () => {

  const accessToken = getDecryptedData("accessToken");
  return new Promise((resolve, reject) => {
    authInstance
      .request({
        url: `/user/admindashboard`,
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
   
        resolve(response); 
      })
      .catch((error) => {

        reject(
          handleApiError(error)
        );
      });
  });
};
export const vendorDashboardEffect = () => {
  const accessToken = getDecryptedData("accessToken");
  return new Promise((resolve, reject) => {
    authInstance
      .request({
        url: `/user/vendordashBoard`,
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(
          handleApiError(error)
        );
      });
  });
};
export {
  AuthEffect,
  SignUpEffect,
  RegisterOtpEffect,
  ForgetPasswordEffect,
  RequestOtpEffect,
  ResendOtpEffect,
  ValidOtpEffect,
  
  viewUserProfileEffect
};
