export const REQUEST_LOGIN_IN_PROGRESS = 'REQUEST_LOGIN_IN_PROGRESS';
export const REQUEST_LOGIN_IN_SUCCESS = 'REQUEST_LOGIN_IN_SUCCESS';
export const REQUEST_LOGIN_IN_FAILURE = 'REQUEST_LOGIN_IN_FAILURE';

export const SIGN_UP_IN_PROGRESS = 'SIGN_UP_IN_PROGRESS';
export const SIGN_UP_IN_SUCCESS = 'SIGN_UP_IN_SUCCESS';
export const SIGN_UP_IN_FAILURE = 'SIGN_UP_IN_FAILURE';
export const VIEW_PROFILE_IN_PROGRESS = 'VIEW_PROFILE_IN_PROGRESS';
export const VIEW_PROFILE_IN_SUCCESS = 'VIEW_PROFILE_IN_SUCCESS';
export const VIEW_PROFILE_IN_FAILURE = 'VIEW_PROFILE_IN_FAILURE';

export const USER_UPDATE_PROGRESS="USER_UPDATE_PROGRESS";
export const USER_UPDATE_SUCCESS="USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE="USER_UPDATE_FAILURE";

export const REGISTER_OTP_IN_PROGRESS = 'REGISTER_OTP_IN_PROGRESS';
export const REGISTER_OTP_IN_SUCCESS = 'REGISTER_OTP_IN_SUCCESS';
export const REGISTER_OTP_IN_FAILURE = 'REGISTER_OTP_IN_FAILURE';

export const FORGOT_PASSWORD_IN_PROGRESS = 'FORGOT_PASSWORD_IN_PROGRESS';
export const FORGOT_PASSWORD_IN_SUCCESS = 'FORGOT_PASSWORD_IN_SUCCESS';
export const FORGOT_PASSWORD_IN_FAILURE = 'FORGOT_PASSWORD_IN_FAILURE';

export const REQUEST_OTP_IN_PROGRESS = 'REQUEST_OTP_IN_PROGRESS';
export const REQUEST_OTP_IN_SUCCESS = 'REQUEST_OTP_IN_SUCCESS';
export const REQUEST_OTP_IN_FAILURE = 'REQUEST_OTP_IN_FAILURE';

export const VALIDATE_OTP_IN_PROGRESS = 'VALIDATE_OTP_IN_PROGRESS';
export const VALIDATE_OTP_IN_SUCCESS = 'VALIDATE_OTP_IN_SUCCESS';
export const VALIDATE_OTP_IN_FAILURE = 'VALIDATE_OTP_IN_FAILURE';

export const RESEND_OTP_IN_PROGRESS = 'RESEND_OTP_IN_PROGRESS';
export const RESEND_OTP_IN_SUCCESS = 'RESEND_OTP_IN_SUCCESS';
export const RESEND_OTP_IN_FAILURE = 'RESEND_OTP_IN_FAILURE';

export const RESET_STATE = 'RESET_STATE';


export const ADMIN_DASHBOARD_PROGRESS='ADMIN_DASHBOARD_PROGRESS'
export const ADMIN_DASHBOARD_SUCCESS='ADMIN_DASHBOARD_SUCCESS'
export const ADMIN_DASHBOARD_FAILURE='ADMIN_DASHBOARD_FAILURE'
export const VENDOR_DASHBOARD_PROGRESS='VENDOR_DASHBOARD_PROGRESS'
export const VENDOR_DASHBOARD_SUCCESS='VENDOR_DASHBOARD_SUCCESS'
export const VENDOR_DASHBOARD_FAILURE='VENDOR_DASHBOARD_FAILURE'
export const OTP_RESET_STATE = 'OTP_RESET_STATE';