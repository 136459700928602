import React from "react";
import { Typography, Grid, Avatar, Box, Dialog, DialogContent, DialogTitle,IconButton,InputAdornment ,Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  StyledBox,
  StyledContainer,
  StyledButton,
  StyledButton1,
  StyledTextField,
  styles,
  backgroundStyle
} from "../../Styles/ComponentStyles/LoginStyles";
import { ScaleLoader } from "react-spinners";
import Logo from "../../image/logo.png";
import { CheckCircle, Cancel, VisibilityOff, Visibility ,InputButton } from "@mui/icons-material"; 
import "../../Styles/login.css";
import { useDispatch, useSelector } from 'react-redux';
import { logInProgress } from '../../redux/auth/authAction';
import { getDecryptedData } from "../../Styles/ComponentStyles/formStyles";
import background from "../../image/foodfestivelLoginbg.png";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogSuccess, setDialogSuccess] = React.useState(false);
   const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();
  const timeoutRef = React.useRef(null); 

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { register, handleSubmit, formState,getValues,reset } = useForm();
  const { errors } = formState;
  const loginState = useSelector((state) => state.login.user) || {};
  const { authSuccess, authError } = loginState;

  const onSubmit = async (data) => {
    const { email, password } = data;
    setLoading(true);
    dispatch(logInProgress({ email, password,type:"2" }));
  };

  React.useEffect(() => {
    if (loading) {
      const timeoutId = setTimeout(() => {
        setLoading(false);
        if (Boolean(authSuccess) === true) {
          const userType = getDecryptedData("User Type");
          if (loginState.message) {
            setOpenDialog(true);
            setDialogSuccess(false);
            setDialogMessage(loginState.message || "Please verify your account with the recent OTP received.");
            timeoutRef.current = setTimeout(() => {
              setOpenDialog(false);
              reset();
            }, 6000);
          } else {
            if (userType === "1") {
              navigate("/dashboard");
            } else if (userType === "2") {
              navigate("/vendor/dashboard");
            } else if (userType === "3") {
              navigate("/customer/dashboard");
            }
          }
        } else if (Boolean(authError) === true) {
          setOpenDialog(true);
          setDialogMessage(authError?.message || "Login Failed! Please check your credentials.");
          setDialogSuccess(false);
          timeoutRef.current = setTimeout(() => {
            setOpenDialog(false);
          }, 1000);
        }
        form.reset();
      }, 600);
  
      return () => clearTimeout(timeoutId);
    }
  }, [loading, authSuccess, authError, loginState.message, navigate]);
  

  const handleCloseDialog = () => {
    setOpenDialog(false);
    clearTimeout(timeoutRef.current); 
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleSignin = () => {
    navigate("/register");
  };

  return (
    <StyledBox >
      <Box  sx={backgroundStyle}  >
      <StyledContainer maxWidth="xs">
          <Grid container spacing={2} className="login-page" >
            <Grid item xs={12} align="center">
              <Avatar src={Logo} className="avatar" />
            </Grid>
            <Grid item xs={6} align="center">
              <Typography variant="p" align="center" fontWeight={600} gutterBottom>
                Login Now
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label="Email"
                id="email"
                size="small"
                fullWidth
                InputProps={{
                  autoComplete: "new-password",
                    }}
                {...register("email", {
                  required: {
                    value: true,
                    message: "Missing Field Email",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Invalid Email",
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                  id="password"
                  size="small"
                  label="Password"
                  
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is Required",
                    },
                  })}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  InputProps={{
                    autoComplete: "new-password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant ="contained"
                fullWidth
                onClick={handleSubmit(onSubmit)}
                disabled={formState.isSubmitting}
              >
                {formState.isSubmitting ? "Signing in..." : "Submit"}
              </StyledButton>
            </Grid>
            <Grid item xs={12} align="center">
              <Link to="/forgot-password" style={styles.forgotPassword}>
                <Typography
                  variant="body2"
                  align="center"
                  padding="8px"
                  gutterBottom
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </Typography>
              </Link>
              {/* <Typography variant="body2" align="center" gutterBottom>
                - - - - - - - - -  or - - - - - - - - -
              </Typography> */}
            </Grid>
            <Grid item xs={12} align="center">
              {/* <StyledButton1 variant="contained" fullWidth onClick={handleSignin}>
                Register
              </StyledButton1> */}
            </Grid>
          </Grid>
          {loading && (
            <>
              <Box
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                backgroundColor="rgba(255, 255, 255, 0.1)"
                style={{ backdropFilter: "blur(8px)", zIndex: 1 }}
              />
              <Box
                position="absolute"
                top="50%"
                left="50%"
                style={{ transform: "translate(-50%, -50%)", zIndex: 2 }}
              >
                <ScaleLoader size={50} color="#1d7f41" />
              </Box>
            </>
          )}
          <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
            {dialogSuccess ? <CheckCircle style={{ color: "green" }} /> : <Cancel style={{ color: "red" }} />}
          </DialogTitle>
          <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body1">
              {dialogMessage}
            </Typography>
          </DialogContent>
          </Dialog>
     
      </StyledContainer>
      </Box>
    </StyledBox>
  );
};

export default Login;