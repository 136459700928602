import { Box, IconButton, Typography, Grid } from "@mui/material";
import StatBox from "./common/graph/StatBox";
import { mockTransactions } from "./common/graph/Data";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import LineChart from "./common/graph/linegraph";
import BarChart from "./common/graph/bargraph";
import { DataGrid } from "@mui/x-data-grid";
import {getColumnWidth } from "../Styles/datagridMQ"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { adminDashboardProgress } from "../redux/auth/authAction";
import { viewTransactionProgress } from "../redux/transaction/transactionAction";
import { viewAllCustomerProgress } from "../redux/Customer/customerAction";

const Dashboard = () => {
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const adminData = useSelector((state) => state.login?.adminDashboard?.data);
    const TotalTransaction = adminData?.data?.count?.TotalTransaction;
    const customerCount = adminData?.data?.count?.customerCount;
    const userCount = adminData?.data?.count?.userCount;
    const vendorCount = adminData?.data?.count?.vendorCount;
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(adminDashboardProgress());
    };
    fetchData();
  }, [dispatch]);
  useEffect(() => {
      dispatch(viewTransactionProgress(1));
    }, []);
 const VendorTransactionData = useSelector(
    (state) => state.transaction?.viewTransactions?.data
  );
  const [vendorList, setVendorList] = useState([]);
  const totalRecords = VendorTransactionData?.totalRecords || 0;
  const customerData = useSelector(
    (state) => state.customer?.viewAllCustomer?.data
  );
  const customerList = customerData?.data || [];
    useEffect(() => {
  const fetchData = async () => {
    await dispatch(viewAllCustomerProgress(1));
  };
  fetchData();
}, [dispatch]);
  const preprocessDatas = (data) =>
    data.map((item) => ({
      id: item.customer_serial_no,
      customerId: item.id,
      first_name: item.first_name || "",
      last_name: item.last_name || "",
      email: item.email || "",
      phone: item.phone || "",
      address: item.address || "",
      totalAmount: item.Wallet?.total_amount || 0,
      availableAmount: item.Wallet?.available_amount || 0,
      usedAmount: item.Wallet?.used_amount || 0,
    }));
  useEffect(() => {
    if (customerData?.success) {
      setRows(preprocessDatas(customerList));
    } else {
      setRows([]);
    }
  }, [customerList]);

  const preprocessData = (data) => {
    if (!data) {
      return [];
    }
    if (!Array.isArray(data)) {
      data = [data];
    }
    const updateData = data.map((item) => {
      const sale = item.Sale;
      if (!Array.isArray(sale)) {
        item.Sale = [sale];
      }
      return item.Sale.map((saleItem, index) => {
        return {
          id: index + 1,
          TransactionId: item.transaction_serial_no || "",
          CustomerName: item.customer_name || "",
          VendorName: item.vendor_name || "",
          AmountPaid: saleItem.price || 0,
          Status: saleItem.status || "Unpaid",
        };
      });
    }).flat();
    return updateData;
  };
    useEffect(() => {
      const updatedVendorList = preprocessData(VendorTransactionData.data);
      setVendorList(updatedVendorList);
      (VendorTransactionData);
      (totalRecords);
      
    }, [VendorTransactionData]);

  return (
    <Box className="dashboard-container">
      {/* GRID & CHARTS */}
      <Grid container spacing={2}>
        {/* ROW 1 */}
        <Grid item xs={12} sm={6} md={3}>
          <Box className="stat-box">
            <StatBox
              title={userCount}
              subtitle="Total users"
              progress="0.75"
              increase={<span style={{ color: "red",fontSize:'39px' }}>↑</span>}
              icon={<EmailIcon sx={{ color: "#1976d2", fontSize: "26px" }} />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box className="stat-box">
            <StatBox
              title={TotalTransaction}
              subtitle="Total Sales"
              progress="0.50"
              increase={<span style={{ color: "green",fontSize:'39px' }}>↷</span>}
              icon={<PointOfSaleIcon sx={{ color: "#388e3c", fontSize: "26px" }} />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box className="stat-box">
            <StatBox
              title={vendorCount}
              subtitle="Total vendors"
              progress="0.30"
              increase={<span style={{ color: "red",fontSize:'39px' }}>↝</span>}
              icon={<PersonAddIcon sx={{ color: "#f57c00", fontSize: "26px" }} />}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box className="stat-box">
            <StatBox
              title={customerCount}
              subtitle="Total Customers"
              progress="0.80"
              increase={<span style={{ color: "green",fontSize:'39px' }}>⤹</span>}
              icon={<TrafficIcon sx={{ color: "#d32f2f", fontSize: "26px" }} />}
            />
          </Box>
        </Grid>

        {/* ROW 2 */}
        <Grid item xs={12} md={8}>
          <Box className="payment-activity">
            <Box mt="25px" p="30px 30px" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontWeight="600" className="chart-title" mb={"10px"}>
                Payment Activity
              </Typography>
              <IconButton>
                {/* <DownloadOutlinedIcon sx={{ fontSize: "26px", color: "#3f51b5" }} /> */}
              </IconButton>
            </Box>
            <Box height={"550px"} m="-20px 0 0 0">
              <LineChart isDashboard={true} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box className="recent-transactions">
            <Box className="transaction-header">
            <Typography variant="h5" fontWeight="600" className="chart-title">
                Recent Transaction
            </Typography>
            </Box>
            {vendorList?.slice(0, 4).map((transaction, i) => (
              <Box key={`${transaction.txId}-${i}`} className="transaction-item">
                <Box>
                  <Typography color="#000" variant="h5" fontWeight="600">
                    {transaction.TransactionId}
                  </Typography>
                  <Typography color="#757575">{transaction.CustomerName}</Typography>
                </Box>
                <Box className="transaction-date">{transaction.AmountPaid}</Box>
                <Box className="transaction-cost">
                  ₹{transaction.Status}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* ROW 3 */}
      <Grid item xs={12} md={6}>
        <Box className="new-clients" sx={{ border: '1px solid #ddd', borderRadius: '10px', padding: '20px', overflowY: 'auto', maxHeight: '550px' }}>
          <Typography variant="h5" fontWeight="600" className="chart-title1">
            New Client
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mt="20px">
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr style={{ backgroundColor: '#f0f0f0' }}>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>ID</th>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>Email</th>
                  <th style={{ border: '1px solid #ddd', padding: '10px' }}>Customer Name</th>
                </tr>
              </thead>
              <tbody>
              {preprocessDatas(customerList)?.slice(0, 6).map((transaction, i) => (
                <tr key={`${transaction.id}-${i}`} style={{ backgroundColor: i % 2 === 0 ? '#fff' : '#f9f9f9' }}>
                  <td style={{ border: '1px solid #ddd', padding: '10px' }}>{transaction.id}</td>
                  <td style={{ border: '1px solid #ddd', padding: '10px' }}>{transaction.email}</td>
                  <td style={{ border: '1px solid #ddd', padding: '10px' }}>{transaction.first_name}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </Box>
        </Box>
      </Grid>

        <Grid item xs={12} md={6}>
          <Box className="sales-quantity">
            <Typography variant="h5" fontWeight="600" className="chart-title">
              Sales Quantity
            </Typography>
            <Box height="550px">
              <BarChart isDashboard={true} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;