export const VENDOR_ADD_SUCCESS="VENDOR_ADD_SUCCESS";
export const VENDOR_ADD_FAILURE="VENDOR_ADD_FAILURE";
export const VENDOR_ADD_PROGRESS="VENDOR_ADD_PROGRESS";

export const VENDOR_UPDATE_SUCCESS="VENDOR_UPDATE_SUCCESS";
export const VENDOR_UPDATE_FAILURE="VENDOR_UPDATE_FAILURE";
export const VENDOR_UPDATE_PROGRESS="VENDOR_UPDATE_PROGRESS";

export const VENDOR_VIEW_SUCCESS="VENDOR_VIEW_SUCCESS";
export const VENDOR_VIEW_FAILURE="VENDOR_VIEW_FAILURE";
export const VENDOR_VIEW_PROGRESS="VENDOR_VIEW_PROGRESS";

export const VENDOR_DELETE_SUCCESS="VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_FAILURE="VENDOR_DELETE_FAILURE";
export const VENDOR_DELETE_PROGRESS="VENDOR_DELETE_PROGRESS";

export const VENDOR_PARTICULAR_VIEW_SUCCESS="VENDOR_PARTICULAR_VIEW_SUCCESS";
export const VENDOR_PARTICULAR_VIEW_FAILURE="VENDOR_PARTICULAR_VIEW_FAILURE";
export const VENDOR_PARTICULAR_VIEW_PROGRESS="VENDOR_PARTICULAR_VIEW_PROGRESS";
export const ADD_VENDOR_RESET_STATE="ADD_VENDOR_RESET_STATE";
export const UPDATE_VENDOR_RESET_STATE="UPDATE_VENDOR_RESET_STATE";