import {
  FORGOT_PASSWORD_IN_FAILURE,
  FORGOT_PASSWORD_IN_PROGRESS,
  FORGOT_PASSWORD_IN_SUCCESS,
  REGISTER_OTP_IN_FAILURE,
  REGISTER_OTP_IN_PROGRESS,
  REGISTER_OTP_IN_SUCCESS,
  REQUEST_LOGIN_IN_FAILURE,
  REQUEST_LOGIN_IN_PROGRESS,
  REQUEST_LOGIN_IN_SUCCESS,
  REQUEST_OTP_IN_FAILURE,
  REQUEST_OTP_IN_PROGRESS,
  REQUEST_OTP_IN_SUCCESS,
  RESEND_OTP_IN_FAILURE,
  RESEND_OTP_IN_PROGRESS,
  RESEND_OTP_IN_SUCCESS,
  SIGN_UP_IN_FAILURE,
  SIGN_UP_IN_PROGRESS,
  SIGN_UP_IN_SUCCESS,
  RESET_STATE,
  VALIDATE_OTP_IN_FAILURE,
  VALIDATE_OTP_IN_SUCCESS,
  VALIDATE_OTP_IN_PROGRESS,
  USER_UPDATE_FAILURE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_PROGRESS,
  VIEW_PROFILE_IN_SUCCESS,
  VIEW_PROFILE_IN_FAILURE,
  VIEW_PROFILE_IN_PROGRESS,
  ADMIN_DASHBOARD_PROGRESS,
  ADMIN_DASHBOARD_SUCCESS,
  ADMIN_DASHBOARD_FAILURE,
  VENDOR_DASHBOARD_PROGRESS,
  VENDOR_DASHBOARD_SUCCESS,
  VENDOR_DASHBOARD_FAILURE,
  OTP_RESET_STATE,
} from './authTypes';

export const logInProgress = payload => {
  return {
    type: REQUEST_LOGIN_IN_PROGRESS,
    payload: payload,
  };
};
export const logInSuccess = payload => {
  return {
    type: REQUEST_LOGIN_IN_SUCCESS,
    payload: payload,
  };
};
export const logInFailure = payload => {
  return {
    type: REQUEST_LOGIN_IN_FAILURE,
    payload: payload,
  };
};
export const signUpInProgress = payload => {
  return {
    type: SIGN_UP_IN_PROGRESS,
    payload: payload,
  };
};
export const signUpInSuccess = payload => {
  return {
    type: SIGN_UP_IN_SUCCESS,
    payload: payload,
  };
};
export const signUpInFailure = payload => {
  return {
    type: SIGN_UP_IN_FAILURE,
    payload: payload,
  };
};
export const RegisterOtpInProgress = payload => {
  return {
    type: REGISTER_OTP_IN_PROGRESS,
    payload: payload,
  };
};
export const RegisterOtpSuccess = payload => {
  return {
    type: REGISTER_OTP_IN_SUCCESS,
    payload: payload,
  };
};
export const RegisterOtpFailure = payload => {
  return {
    type: REGISTER_OTP_IN_FAILURE,
    payload: payload,
  };
};


export const ForgetPasswordInProgress = payload => {
  return {
    type: FORGOT_PASSWORD_IN_PROGRESS,
    payload: payload,
  };
};
export const ForgetPasswordSuccess = payload => {
  return {
    type: FORGOT_PASSWORD_IN_SUCCESS,
    payload: payload,
  };
};
export const ForgetPasswordFailure = payload => {
  return {
    type: FORGOT_PASSWORD_IN_FAILURE,
    payload: payload,
  };
};



export const ValidateOtpInProgress = payload => {
  return {
    type: VALIDATE_OTP_IN_PROGRESS,
    payload: payload,
  };
};
export const ValidateOtpSuccess = payload => {
  return {
    type: VALIDATE_OTP_IN_SUCCESS,
    payload: payload,
  };
};
export const ValidateOtpFailure = payload => {
  return {
    type: VALIDATE_OTP_IN_FAILURE,
    payload: payload,
  };
};

export const RequestOtpInProgress = payload => {
  return {
    type: REQUEST_OTP_IN_PROGRESS,
    payload: payload,
  };
};
export const RequestOtpSuccess = payload => {
  return {
    type: REQUEST_OTP_IN_SUCCESS,
    payload: payload,
  };
};
export const RequestOtpFailure = payload => {
  return {
    type: REQUEST_OTP_IN_FAILURE,
    payload: payload,
  };
};



export const ResendOtpInProgress = payload => {
  return {
    type: RESEND_OTP_IN_PROGRESS,
    payload: payload,
  };
};
export const ResendOtpSuccess = payload => {
  return {
    type: RESEND_OTP_IN_SUCCESS,
    payload: payload,
  };
};
export const ResendOtpFailure = payload => {
  return {
    type: RESEND_OTP_IN_FAILURE,
    payload: payload,
  };
};

export const ResetState = () => {
  return {
    type: RESET_STATE,
  };
};
export const viewProfileInProgress = payload => {
  return {
    type: VIEW_PROFILE_IN_PROGRESS,
    payload: payload,
  };
};
export const viewProfileInSuccess = payload => {
  return {
    type: VIEW_PROFILE_IN_SUCCESS,
    payload: payload,
  };
};
export const viewProfileInFailure = payload => {
  return {
    type: VIEW_PROFILE_IN_FAILURE,
    payload: payload,
  };
};
export const updateUserProgress = (payload) => {
    return {
    type: USER_UPDATE_PROGRESS,
    payload,
    };
};

export const updateUserSuccess = (payload) => {
    return {
    type: USER_UPDATE_SUCCESS,
    payload,
    };
};

export const updateUserFailure = (payload) => {
    return {
    type: USER_UPDATE_FAILURE,
    payload,
    };
};






export const adminDashboardProgress = () => {
  return {
  type: ADMIN_DASHBOARD_PROGRESS,
  };
};

export const adminDashboardSuccess = (payload) => {
  return {
  type: ADMIN_DASHBOARD_SUCCESS,
  payload,
  };
};

export const adminDashboardFailure = (payload) => {
  return {
  type: ADMIN_DASHBOARD_FAILURE,
  payload,
  };
};

export const vendorDashboardProgress = () => {
  return {
  type: VENDOR_DASHBOARD_PROGRESS,
  };
};

export const vendorDashboardSuccess = (payload) => {
  return {
  type: VENDOR_DASHBOARD_SUCCESS,
  payload,
  };
};

export const vendorDashboardFailure = (payload) => {
  return {
  type: VENDOR_DASHBOARD_FAILURE,
  payload,
  };
};

export const OtpReset = (payload) => {
  return {
  type: OTP_RESET_STATE,
  payload,
  };
};