import { defaultStatus } from '../../screen/common/Dialogbox'
import {
  FORGOT_PASSWORD_IN_FAILURE,
  FORGOT_PASSWORD_IN_PROGRESS,
  FORGOT_PASSWORD_IN_SUCCESS,
  REGISTER_OTP_IN_FAILURE,
  REGISTER_OTP_IN_PROGRESS,
  REGISTER_OTP_IN_SUCCESS,
  REQUEST_LOGIN_IN_FAILURE,
  REQUEST_LOGIN_IN_PROGRESS,
  REQUEST_LOGIN_IN_SUCCESS,
  REQUEST_OTP_IN_FAILURE,
  REQUEST_OTP_IN_PROGRESS,
  REQUEST_OTP_IN_SUCCESS,
  RESEND_OTP_IN_FAILURE,
  RESEND_OTP_IN_PROGRESS,
  RESEND_OTP_IN_SUCCESS,
  RESET_STATE,
  SIGN_UP_IN_FAILURE,
  SIGN_UP_IN_PROGRESS,
  SIGN_UP_IN_SUCCESS,
  VALIDATE_OTP_IN_FAILURE,
  VALIDATE_OTP_IN_PROGRESS,
  VALIDATE_OTP_IN_SUCCESS,
  VIEW_PROFILE_IN_PROGRESS,
  VIEW_PROFILE_IN_SUCCESS,
  VIEW_PROFILE_IN_FAILURE,
  USER_UPDATE_PROGRESS,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  VENDOR_DASHBOARD_PROGRESS,
  VENDOR_DASHBOARD_FAILURE,
  VENDOR_DASHBOARD_SUCCESS,
  ADMIN_DASHBOARD_PROGRESS,
  ADMIN_DASHBOARD_FAILURE,
  ADMIN_DASHBOARD_SUCCESS,
  OTP_RESET_STATE,
} from './authTypes'

const initialState = {
  user: { authInProgress: false, authSuccess: false, authError: false },
  NewUser: { Success: false, error: false },
  registertOtp: { ...defaultStatus },
  ForgetPassword: { ...defaultStatus },
  ValidOtp: { ...defaultStatus },
  requestOtp: { ...defaultStatus },
  resendOtp: { ...defaultStatus },
  reset: { ...defaultStatus },
  viewProfile: { ...defaultStatus },
  updateUser: {...defaultStatus },
  adminDashboard: { ...defaultStatus },
  vendorDashboard: {...defaultStatus },
}

const AuthReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case REQUEST_LOGIN_IN_PROGRESS:
      return {
        ...state,
        user: { ...payload, authInProgress: true },
        NewUser: {
          ...defaultStatus,
          inProgress: false,
          success: false,
          error:false,
          errormessage: ""
        }
      }
    case REQUEST_LOGIN_IN_SUCCESS:
      return {
        ...state,
        user: { ...payload, authSuccess: true, message: payload?.message },
        NewUser: {
          ...defaultStatus,
          inProgress: false,
          success: false,
          error:false,
          errormessage: ""
        }
      }
    case REQUEST_LOGIN_IN_FAILURE:
      return {
        ...state,
        user: {
          ...payload,
          authSuccess: false,
          authError: true,
          errormessage: payload
        },
        NewUser: {
          ...defaultStatus,
          inProgress: false,
          success: false,
          error:false,
          errormessage: ""
        }
      }
    case SIGN_UP_IN_PROGRESS:

      return {
        ...state,
        NewUser: { ...defaultStatus, ...payload, inProgress: true,success:false, error:false,errormessage: "" }
      }
    case SIGN_UP_IN_SUCCESS:

      return {
        ...state,
        NewUser: {
          ...defaultStatus,
          inProgress: false,
          success: true,  
          error:false,
          errormessage: "",
          message: payload,
        }
      }
    case SIGN_UP_IN_FAILURE:

      return {
        ...state,
        NewUser: {
          ...defaultStatus,
          inProgress: false,
          success: false,
          error:true,
          errormessage: payload
        }
      }
    case REGISTER_OTP_IN_PROGRESS:
      return {
        ...state,
        registertOtp: { ...defaultStatus, ...payload, inProgress: true }
      }
    case REGISTER_OTP_IN_SUCCESS:
      return {
        ...state,
        registertOtp: { ...defaultStatus, ...payload, success: true }
      }
    case REGISTER_OTP_IN_FAILURE:
      return {
        ...state,
        registertOtp: { ...defaultStatus, ...payload, error: true }
      }
    case FORGOT_PASSWORD_IN_PROGRESS:

      return {
        ...state,
        ForgetPassword: { ...defaultStatus, ...payload, inProgress: true }
      }
    case FORGOT_PASSWORD_IN_SUCCESS:
      return {
        ...state,
        ForgetPassword: { ...defaultStatus, message:payload, success: true }
      }
    case FORGOT_PASSWORD_IN_FAILURE:
      return {
        ...state,
        ForgetPassword: { ...defaultStatus, message:payload, error: true }
      }
    case REQUEST_OTP_IN_PROGRESS:
      return {
        ...state,
        requestOtp: { ...defaultStatus, ...payload, inProgress: true }
      }
    case REQUEST_OTP_IN_SUCCESS:
      return {
        ...state,
        requestOtp: { ...defaultStatus, ...payload, success: true }
      }
    case REQUEST_OTP_IN_FAILURE:
      return {
        ...state,
        requestOtp: { ...defaultStatus, ...payload, error: true }
      }
    case RESEND_OTP_IN_PROGRESS:
      return {
        ...state,
        resendOtp: { ...defaultStatus, ...payload, inProgress: true }
      }
    case RESEND_OTP_IN_SUCCESS:
      return {
        ...state,
        resendOtp: { ...defaultStatus, ...payload, success: true }
      }
    case RESEND_OTP_IN_FAILURE:
      return {
        ...state,
        resendOtp: { ...defaultStatus, ...payload, error: true }
      }
    case RESET_STATE:
      return {
        ...state,
        NewUser: { ...defaultStatus },
        user: { authInProgress: false, authSuccess: false, authError: false },
        registertOtp: { ...defaultStatus },
        ForgetPassword: { ...defaultStatus },
        requestOtp: { ...defaultStatus,success:false },
        resendOtp: { ...defaultStatus },
        reset: { ...defaultStatus },
        updateUser: {...defaultStatus },
      }
      case VALIDATE_OTP_IN_PROGRESS:
        return {
          ...state,
          ValidOtp: { ...defaultStatus, ...payload, inProgress: true }
        }
        case VALIDATE_OTP_IN_SUCCESS:
          return {
            ...state,
            ValidOtp: { ...defaultStatus, ...payload, success: true }
          }
          case VALIDATE_OTP_IN_FAILURE:
            return {
              ...state,
              ValidOtp: { ...defaultStatus, message:payload, error: true }
            }
            case VIEW_PROFILE_IN_PROGRESS:
              return {
                ...state,
                viewProfile: { ...defaultStatus, ...payload, inProgress: true },
              };
            case VIEW_PROFILE_IN_SUCCESS:
              return {
                ...state,
                viewProfile: {
                  ...defaultStatus,
                  inProgress: false,
                  success: true,
                  error: false,
                  errormessage: "",
                  data: payload,
                },
              };
            case VIEW_PROFILE_IN_FAILURE:
              return {
                ...state,
                viewProfile: {
                  ...defaultStatus,
                  inProgress: false,
                  success: false,
                  error: true,
                  errormessage: payload,
                },
              };
        
            case USER_UPDATE_PROGRESS:
              return {
                ...state,
                updateUser: {
                  ...defaultStatus,
                  inProgress: true,
                  success: false,
                  error: false,
                },
              };
            case USER_UPDATE_SUCCESS:
              return {
                ...state,
                updateUser: {
                  ...defaultStatus,
                  inProgress: false,
                  success: true,
                  errormessage: false,
                  data: payload,
                },
              };
            case USER_UPDATE_FAILURE:
              return {
                ...state,
                updateUser: {
                  ...defaultStatus,
                  inProgress: false,
                  success: false,
                  error: true,
                  errormessage: payload,
                },
              };
              case ADMIN_DASHBOARD_SUCCESS:
                return {
                  ...state,
                  adminDashboard: {
                    ...defaultStatus,
                    data: payload,
                    success: true,
                  },
                };
              
            case ADMIN_DASHBOARD_FAILURE:
              return {
                ...state,
                adminDashboard: {
                  ...defaultStatus,
                  inProgress: false,
                  success: false,
                  error: true,
                  errormessage: payload,
                },
              };
              case ADMIN_DASHBOARD_PROGRESS:
              return {
                ...state,
                adminDashboard: {
                  ...defaultStatus,
                  inProgress: true,
                  success: false,
                  error: false,
                },
              };
            case VENDOR_DASHBOARD_SUCCESS:
              return {
                ...state,
                vendorDashboard: {
                  ...defaultStatus,
                  inProgress: false,
                  success: true,
                  errormessage: false,
                  data: payload,
                },
              };
            case VENDOR_DASHBOARD_FAILURE:
              return {
                ...state,
                vendorDashboard: {
                  ...defaultStatus,
                  inProgress: false,
                  success: false,
                  error: true,
                  errormessage: payload,
                },
              };
              case VENDOR_DASHBOARD_PROGRESS:
              return {
                ...state,
                vendorDashboard: {
                  ...defaultStatus,
                  inProgress: true,
                  success: false,
                  error: false,
                },
              };
              case OTP_RESET_STATE:
                return {
                  ValidOtp:{...defaultStatus,
                    inProgress:false,
                    success:false,
                    error:false,
                    errormessage:"",
                    data:{}
                },
            }
    default:
      return state
  }
}
export default AuthReducer

